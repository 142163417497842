import React from 'react';
import Layout from '../Layout';
import "./impact.scss";
import shareicon from "../../images/volunteer-icon-3.png"
import img2 from "../../images/pdf1.jpg"
import img1 from "../../images/pdf2.jpg"
import img3 from "../../images/pdf3.jpg"
import img4 from "../../images/pdf4.jpg"
import SEO from '../seo';

import { RWebShare } from 'react-web-share';
const ImpactPage = ({ location }) => {

    return (
        <Layout>
            <SEO
                title="Chinmaya Mission | Multi Level Impact in Education & Development"
                description="Learn more about the Mission's initiatives- each is driving large scale social impact."
                keywords=""
                img={`https://${location.host}/link-preview.jpg`}
            />
            <div className='impact'>
                <h1>Impact</h1>

                <div className="divider"></div>

                <br />
                <p>
                    “Every act of kindness creates a ripple with no end.“<br /><br />
                    Founded on Pujya Gurudev Swami Chinmayananada’s sankalp (vision), the Chinmaya Mission is committed to global spiritual and social upliftment.<br /><br />
                    Our participation in the Tata Mumbai Marathon 2023 symbolizes the miles we have yet to go, as well as the heights already scaled. Rather than just a run for a cause, this is an exclusive platform to bring together like-minded individuals from all walks of life in a concerted effort to bring about positive change.<br /><br />
                    Our plans for upliftment at all levels are already in motion- facilitating integrated development in over 700 villages of India. All proceeds go towards solving for quality, value-based education, and upskilling for marginalized communities.<br /><br />
                    Learn more about the impact of each of our initiatives and share it with your network. Every bit of support goes a long way in helping us achieve our goals and has an impact for years to come.
                </p>


                <div className='impact__atglance'>
                    <h2>At Glance</h2>
                    <div className='impact__counters'>
                        <div className='impact__counters__card'>
                            <div className='amount'>₹5000000+</div>
                            <div className='title'>Scholarships awarded</div>
                        </div>

                        <div className='impact__counters__card'>
                            <div className='amount'>40,000+</div>
                            <div className='title'>People received Self Help Group (SHG) training</div>
                        </div>

                        <div className='impact__counters__card'>
                            <div className='amount'>16,452+</div>
                            <div className='title'>Rural community members received skill-training</div>
                        </div>
                    </div>
                </div>

                <div className='impact__shareables'>
                    <div className='impact__shareables__content'>
                        <div className='impact__shareables__card'>
                            <span>Educational Impact at the K-12 school level</span>
                            <br />
                            Chinmaya Vidyalaya(s)
                            <img className='impact__shareables__card__img' src={img1}></img>

                            <a href="/TMM_Shareable_CV.pdf" download="TMM_Shareable_CV.pdf" >
                                <i className='fa fa-download'></i>
                            </a>

                            <RWebShare data={{
                                text: "",
                                url: `https://${location.host}/TMM_Shareable_CV.pdf`,
                                title: "Share this article"
                            }}><button className='share-btn'> <a >
                                <i className='fa fa-share'></i>
                            </a></button>
                            </RWebShare>
                        </div>
                        <div className='impact__shareables__card'>
                            <span>Higher Education with a difference </span>
                            <br />
                            Chinmaya Vishwa Vidyapeeth

                            <img className='impact__shareables__card__img' src={img2}></img>

                            <a href="/TM_Shareable_CVV.pdf" download="TMM_Shareable_CVV.pdf" >
                                <i className='fa fa-download'></i>
                            </a>
                            <RWebShare data={{
                                text: "",
                                url: `https://${location.host}/TMM_Shareable_CVV.pdf`,
                                title: "Share this article"
                            }}><button className='share-btn'> <a >
                                <i className='fa fa-share'></i>
                            </a></button>
                            </RWebShare>

                        </div>
                        <div className='impact__shareables__card'>
                            <span>Women empowerment</span>
                            <br />
                            Chinmaya Organization for Rural Development (CORD)

                            <img className='impact__shareables__card__img' src={img3}></img>

                            <a href="/TMM_Shareable_CORD.pdf" download="TMM_Shareable_CORD.pdf" >
                                <i className='fa fa-download'></i>
                            </a>

                            <RWebShare data={{
                                text: "",
                                url: `https://${location.host}/TMM_Shareable_CORD.pdf`,
                                title: "Share this article"
                            }}><button className='share-btn'> <a >
                                <i className='fa fa-share'></i>
                            </a></button>
                            </RWebShare>
                        </div>
                        <div className='impact__shareables__card'>
                            <span>Bringing a Vision to life</span>
                            <br />
                            Chinmaya Mission

                            <img className='impact__shareables__card__img' src={img4}></img>

                            <a href="/TMM_Shareable.pdf" download="TMM_Shareable.pdf" >
                                <i className='fa fa-download'></i>
                            </a>

                            <RWebShare data={{
                                text: "",
                                url: `https://${location.host}/TMM_Shareable.pdf`,
                                title: "Share this article"
                            }}><button className='share-btn'> <a >
                                <i className='fa fa-share'></i>
                            </a></button>
                            </RWebShare>
                        </div>
                    </div>
                </div>
                <div className='bottom-panel'>
                    <p>Lasting change is never a sprint, always a marathon.</p>
                    <a className='btn' href="/fundraisers/">Reach out today</a>
                </div>
            </div>
        </Layout >
    );
}

export default ImpactPage;
